.thankyou-background {
    background: var(--rad, radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8F92DD 100%));
    width: 100%;
    /* height: 1024px; */
    height: 100%;
    overflow: hidden;
    /* max-width: 1440px; */
    margin: 0 auto;
    /* position: absolute; */
}

.hero-img {
    width: 40%;
    position: relative;
    margin-top: 90px;
    margin-left: 560px;
}

.thankyou-text {
    color: white;
    margin-top: 20px;
    text-align: center;
    margin-left: -250px;
    position: absolute;
    font-family: Poppins;
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.thankyou-main {
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins-Bold;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.main {
    margin: 0 auto;
}

.email-text {
    font-family: Poppins-Bold;
    color: #FFFFFF;
    word-wrap: break-word!important; 
    overflow-wrap: break-word!important; 
    white-space: normal!important;
    display: inline-block!important;
    text-align: center;
    width: 100%;
}

.email-text:hover {
    color: #dfdddd;
}

@media (min-width: 576px ) and (max-width: 991px) {
    .thankyou-background {
        background: var(--rad, radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8F92DD 100%));
        width: 100%;
        height: 920px;
        margin: 0 auto;
        /* position: absolute; */
    }

    .thankyou-main {
        font-size: 50px;
    }
    
    .thankyou-text {
        /* margin-top: 190px; */
        margin-top: 70px;
        margin-left: 24px;
        font-size: 24px;
        width: 94%;

    }

    .hero-img {
        width: 60%;
        position: relative;
        /* margin-top: 300px;
        margin-left: 222px; */
        margin-top: 420px;
        margin-left: 92px;
    }
}

@media (max-width: 576px) {
    .thankyou-background {
        width: 100%;
        /* height: 1000px; */
        /* height: 450px; */
        margin: 0 auto;
    }

    .hero-img {
        /* width: 90%; */
        /* margin-left: 20px;
        margin-top: 330px; */
        /* margin-left: 20px;
        margin-top: 649px; */
        /* margin-left: 70px;
        margin-top: 260px;
        width: 55%; */
        margin-left: 15px;
        /* margin-top: 580px; */
        margin-top: 415px;
        width: 80%;
    }

    .thankyou-main {
        /* font-size: 30px!important; */
        font-size: 25px!important;
    }

    .thankyou-text {
        font-size: 14px!important;
        /* margin-top: 134px; */
        margin-top: 70px;
        margin-left: 13px;
        width: 90%;
        line-height: 24px;
        /* gap: 2px; */
    }

    .main {
        flex-direction: column;
    }
}