.showbtn {
    position: fixed;
    /* bottom: 125px; */
    bottom: 30px;
    /* top: 50px; */
    right: 20px;
    height: 50px;
    width: 50px;
    font-size: 70px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.showbtn:hover .arrow-svg rect{
    fill: white;
}

.showbtn:hover .arrow-svg path{
    stroke: #4E50A8;
}