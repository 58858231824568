/* #industryusecases {
  padding-top: 80px;
} */
:target::before {
  content: "";
  display: block;
  height: 60px; /* Adjust this to match your header's height */
  margin: -60px 0 0; /* Adjust this to match your header's height */
}
.Functional-Use-Cases .Functional-Use-Cases-Card:hover {
  background-color: #4e50a8;
}
.Functional-Use-Cases-Card:hover .Card-Title,
.Card-Title-Row:hover .Card-Image-1,
.Functional-Use-Cases-Card:hover .Card-Description {
  color: white;
}
.Functional-Use-Cases-Card:hover .Card-Image-2 {
  display: block;
}
.Functional-Use-Cases-Main {
  margin: auto;
  margin-top: 20px;
  max-width: 1440px;
}
.Functional-Use-Cases {
  margin: auto;
  margin-left: 64px;
  margin-right: 32px;
  /* margin-top: 80px; */
  flex-grow: 0;
  max-width: 1440px;
}
.UseCase {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}
.FunctionalUseCase {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.Functional-Use-Cases .Functional-Use-Cases-Card {
  height: 304px;
  margin-inline-start: 0px;
  margin-inline-end: 32px;
  margin-bottom: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(85, 87, 173, 0.25);
}
.Card-Title-Row {
  display: flex;
  padding: 24px 24px 0px 24px;
  justify-content: space-between;
  align-items: center;
}
.Functional-Use-Cases .Card-Title {
  color: var(--Primary-Color, #4e50a8);
  font-family: poppins;
  font-size: 20px;
  font-style: bold;
  font-weight: 600;
  line-height: 32px;
}
.Card-Title-Row .Card-Image-1 {
  /* width: 32px;
  height: 32px;
  flex-shrink: 0; */

  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.Card-Title-Row .Card-Image-2 {
  display: none;
}
.Functional-Use-Cases .Card-Description {
  height: 224px;
  text-align: justify;
  color: #212121;
  padding: 24px 24px 12px;
  /* color: #212121;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
   */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 576px) {
  .Functional-Use-Cases {
    margin-left: 16px;
    margin-right: 8px;
    /* margin-top: 40px; */
  }
  .UseCase {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }
  .FunctionalUseCase {
    /* margin-top: 16px; */
    margin-bottom: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
  .Functional-Use-Cases .Functional-Use-Cases-Card {
    height: auto;
    margin-inline-start: 0px;
    margin-inline-end: 8px;
    margin-bottom: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 11.011px 0px rgba(85, 87, 173, 0.25);
  }
  .Card-Title-Row {
    display: flex;
    padding: 16px 16px 16px 16px;
    justify-content: space-between;
    align-items: center;
  }
  .Functional-Use-Cases .Card-Title {
    color: var(--Primary-Color, #4e50a8);
    font-size: 16px;
    font-style: bold;
    font-weight: 500;
    line-height: 24px;
  }
  .Card-Title-Row .Card-Image-1 {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .Functional-Use-Cases .Card-Description {
    height: auto;
    color: #000;
    padding: 0px 16px 16px 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 14px;
  }
  .Modal .IndustryLink {
    text-align: end;
    text-decoration: none;
    color: #4e50a8 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 0px;
    padding-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .Card-Title-Row .Card-Image-2 {
    width: 16px;
    height: 16px;
  }
}
@media (min-width: 577px) and (max-width: 991px) {
  .Functional-Use-Cases {
    margin-left: 64px;
    margin-right: 32px;
    margin-top: 40px;
  }
  .UseCase {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
  }
  .FunctionalUseCase {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
  }
  .Functional-Use-Cases .Functional-Use-Cases-Card {
    margin-inline-start: 0px;
    margin-inline-end: 32px;
    margin-bottom: 24px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 11.011px 0px rgba(85, 87, 173, 0.25);
  }
  .Card-Title-Row {
    display: flex;
    padding: 16px 16px 0px 16px;
    justify-content: space-between;
    align-items: center;
  }
  .Functional-Use-Cases .Card-Title {
    color: var(--Primary-Color, #4e50a8);
    font-size: 16px;
    font-style: bold;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
  }
  .Card-Title-Row .Card-Image-1 {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .Card-Title-Row .Card-Image-2 {
    width: 24px;
    height: 24px;
  }
  .Functional-Use-Cases .Card-Description {
    color: #000;
    padding: 16px 16px 16px 16px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1439px) {
  .Functional-Use-Cases .Functional-Use-Cases-Card {
    height: 340px;
  }
}
.ModalTable {
  min-width: 130px;
  height: 40px;
}
@media (min-width: 577px) {
  .Modal {
    margin-top: -10px !important;
    overflow: auto;
  }
  /* .ModalTable{
    min-width: 80px;
  } */
  .ModalQuestion {
    margin-left: 5px !important;
    margin-bottom: 16px;
    border-radius: 18px;
    width: 513px;
    padding: 12px;
    background: #e9e9ff;
    color: #212121;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .ModalAnswer {
    white-space: pre-line;
    font-family: Poppins;
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 18px;
    background: var(
      --rad,
      radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8f92dd 100%)
    );
    max-width: 830px;
    padding: 12px;
    color: var(--white, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .questionChatBotName {
    /* margin-bottom: 20px !important; */
    margin-top: 40px;
  }
  .ModalImageContainer {
    display: "flex";
    flex-direction: "column";
    height: "65px";
    width: "80px";
    justify-items: "center";
    align-items: "center";
    text-align: "center";
  }
  .ModalImage {
    margin-bottom: 10px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .ImageName {
    max-width: 100px;
    margin-bottom: 0px !important;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: 5px;
  }
  .Modal .IndustryLink {
    text-align: end;
    text-decoration: none;
    color: #4e50a8 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 0px;
    padding-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .Modal .IndustryLink:hover {
    text-decoration: none;
    color: #4e50a8;
  }
  .IndustryLink .RightArrow {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
  .LeftArrow {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
@media (max-width: 576px) {
  .Modal {
    margin-top: 90px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #4e50a8;
  }
  .ModalQuestion {
    /* margin-bottom: 16px; */
    border-radius: 18px;
    background: #e9e9ff;
    padding: 16px;
    color: #212121;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 6px !important;
  }
  .ModalAnswer {
    white-space: pre-line;
    border-radius: 18px;
    background: var(
      --rad,
      radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8f92dd 100%)
    );
    padding: 16px;
    color: var(--white, #fff);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-right: 6px;
    margin-top: 10px;
    width: auto;
    margin-top: 10px !important;
  }
  .ModalTable {
    /* height: 20px; */
  }
  .questionChatBotName {
    /* margin-bottom: 20px !important; */
    max-width: 250px !important;
    margin-top: 20px;
    text-align: left;
    text-wrap: wrap;
  }
  .ModalImage {
    width: 25px;
    height: 25px;
    padding: 0px !important;
  }
  .ImageName {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0px;
  }
}
