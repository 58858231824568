:target::before {
  content: "";
  display: block;
  height: 60px; /* smae as header's height */
  margin: -60px 0 0; /* same as header's height */
}
.request-form {
  display: flex;
  justify-content: center;
  padding: 0px;

  max-width: 1440px;
  margin: 0 auto;
}

.demo-row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; /* Make items stretch to the same height */
}

.demo-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px !important;
  margin-left: -2px !important;
  /* height: 100%; */
}

.demo-img {
  max-width: 100%;
  height: 100%;
  /* margin-left: 4px !important; */
  object-fit: cover; /* Make sure the image covers the container */
}

.form-container {
  /* padding-top: 80px; */
  /* padding: 20px; */
  /* margin-top: 60px; */
  padding-left: 20px !important;
  /* margin-right: 42px; */
  margin-left: 9px;
  margin-right: 14px; /*35px*/
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-title {
  margin-bottom: 20px;
  padding-left: 10px;
  font-family: Poppins-medium;
  margin-top: 16px;
}

.submit-button {
  display: flex;
  width: 32%;
  height: 60%;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 0;
  background: #4e50a8;
  color: white;
  /* margin-left:; */
  border: 1px solid #4e50a8;
}

.submit-button:hover {
  background-color: #3a3b7e;
  color: white;
  
}

.submit-container-1 {
  display: flex;
  flex-grow: 1;
  margin: auto;
  margin-top: 20px !important;
}

.demo-simple-select-filled-label {
  color: red;
}

/* .css-1ty026z {
  padding: 0px!important;
} */

.progresscircle {
  margin-top: 40%!important;
}

.dialog-request .css-ypiqx9-MuiDialogContent-root {
  padding: 0px!important;
}

@media (max-width: 576px) {
  .form-container {
    padding: 10px;
    margin-right: 16px !important;
    /* margin-left: 16px !important; */
    padding-left: 16px !important;
  }
  .demo-image{
    padding-left: 0px !important;
  }

  .demo-img {
    width: 100%;
    margin-left: 16px !important;
  }
  .form-text {
    font-size: 12px;
  }

  .submit-button {
    width:94%;
    border-radius: 4px;
  }

  .submit-container-1 {
    margin-left: 0px!important;
    margin-top: 16px!important;
  }

  .progresscircle {
    margin-bottom: 40%!important;
  }
}

@media (min-width: 577px) {
  .form-container {
    padding: 16px;
    padding-left: 0px;
  }
  .demo-row {
    display: flex;
    flex-wrap: wrap;
    align-items: none;
  }
  .demo-image {
    margin-left: 0px !important;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .submit-button {
    width: 30%
  }
}
