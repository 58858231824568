/* .video-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fafaff;
    margin: 0;
  }
  
  .video-wrapper {
    position: relative;
    width: 100%;
    max-width: 800px; 
    aspect-ratio: 16 / 9; 
    overflow: hidden;
    border-radius: 8px;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

  .video-section {
    position: relative;
  }
  
  .video-wrapper {
    position: relative;
    padding-top: 56.25%; 
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  
  .fullscreen-button {
    position: absolute;
   
    bottom: -4px;
    right: 5px;
    background: rgba(0,0,0,0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .fullscreen-button:hover {
    background: rgba(0,0,0,0.7);
  }
  
  .fullscreen-active .fullscreen-button {
    display: none;
  }


  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    object-fit: cover; 
  } */

  


  /* .video-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fafaff;
    margin: 0;
  }
  
  .video-wrapper {
    position: relative;
    width: 100%;
    max-width: 800px; 
    aspect-ratio: 16 / 9; 
    overflow: hidden;
    border-radius: 8px;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .fullscreen-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  } */

  .video-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fafaff;
    margin: 0;
  }
  
  .video-wrapper {
    position: relative;
    width: 100%;
    max-width: 800px; 
    aspect-ratio: 16 / 9; 
    overflow: hidden;
    border-radius: 8px;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .fullscreen-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  }
  
  .video-section .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-section {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    width: 100%;
  }