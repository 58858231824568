.backgroung-image {
  background-image: url("../../../public/Images/background_1440px.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 35rem;    */
  display: flex;
  position: relative;
  /* max-width: 1440px; */
  margin: auto;
  margin-top: 40px;
}

:target::before {
  content: "";
  display: block;
  height: 60px;
  /* Adjust this to match your header's height */
  margin: -60px 0 0;
  /* Adjust this to match your header's height */
}

.hero-image {
  width: 40%;
  position: relative;
  margin-top: 35px;
  margin-left: 580px;
}

.hero-text {
  color: white;
  margin-top: -450px;
  font-size: 30px;
  text-align: left;
  margin-left: -10px;
  position: absolute;
  font-family: Poppins;
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  width: 73%;
}

.enplify-title {
  font-family: "Poppins-Bold", sans-serif;
  color: var(--white, #fff);
}

.enplify-ai {
  font-family: "Poppins-Bold", sans-serif;
  color: var(--white, #fff);
  font-size: 15px;
}

/*Mobile*/
@media (max-width: 576px) {
  .backgroung-image {
    background-image: url("../../../public/Images/BG_360PX.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25rem;
    display: flex;
    position: relative;
  }

  .hero-image {
    width: 55%;
    position: relative;
    /* margin-top: 80px; */
    margin-top: 88px;
    margin-left: 143px;
  }

  .hero-text {
    /* margin-top: -295px; */
    margin-top: -302px;
    text-align: left;
    margin-left: 10px;
    position: absolute;
    font-family: "Poppins", sans-serif;
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
    width: 57%;
  }
}

/*Tablet*/
@media (min-width: 576px) and (max-width: 991px) {
  .backgroung-image {
    background-image: url("../../../public/Images/Bg_Tab_991px.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    display: flex;
    position: relative;
  }

  .hero-image {
    width: 55%;
    position: relative;
    margin-top: 280px;
    margin-left: 254px;
  }

  /* .hero-text {
    color: white;
    margin-top: -560px;
    font-size: 30px;
    text-align: left;
    margin-left: -10px;
    position: absolute;
    font-family: Poppins;
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 30px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; 
    letter-spacing: 1px;
    width: 85%;
  } */
  .hero-text {
    color: white;
    margin-top: -564px;
    font-size: 30px;
    text-align: left;
    margin-left: -10px;
    position: absolute;
    font-family: Poppins;
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 1px;
    width: 100%;
  }
}

/*Laptop*/
@media (min-width: 992px) and (max-width: 1199px) {
  .backgroung-image {
    background-image: url("../../../public/Images/background_1440px.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    display: flex;
    position: relative;
  }

  .hero-image {
    width: 40%;
    position: relative;
    margin-top: 90px;
    margin-left: 510px;
  }

  .hero-text {
    color: white;
    margin-top: -420px; /*-490px;*/
    font-size: 30px;
    text-align: left;
    margin-left: -2px;
    position: absolute;
    font-family: Poppins;
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0px;
    width: 71%;
  }
}