body, html {
    height: 100%;
    margin: 0;
}

.login-component {
    background: var(--rad, radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8F92DD 100%));
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    /* max-width: 1440px;
    margin: 0 auto; */
}

.main-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    padding: 20px;
}

.sign-in {
    z-index: 1;
    width: 40%;
    max-width: 400px;
    margin-top: -160px;
    background-color: white!important;
}

.login-hero-component {
    /* width: 40%;
    max-width: 600px;
    position: relative;
    z-index: 1; */
    width: 58%;
    max-width: 800px;
    position: relative;
    z-index: 1;
    top: 160px;
}

/* .css-nzinn5-MuiContainer-root { */
    /* background: white; */
    /* margin-left: auto!important; */
    /* border-radius: 4px; */
    /* width: 100%; */
    /* position: relative;
    top: 30px; */
/* } */

.brand-img {
    padding: 25px;
    width: 60%;
}

.login-submit-button {
    display: flex;
    width: 98%;
    height: 100%;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border: 0;
    border-radius: 16px;
    background: var(--rad, radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8F92DD 100%));
    color: white;
}

.Microsoft-login-button:hover {
    background-color: white;
    color: #373995;
}
  
  .submit-container {
    display: flex;
    flex-grow: 1;
    margin-top: 20px;
    padding-bottom: 70px;
  }

@media (max-width: 991px) {
    .main-container {
        flex-direction: column;
        align-items: center;
    }

    .sign-in {
        width: 60%;
        max-width: none;
    }

    .login-hero-component  {
        width: 60%;
        max-width: none;
    }

    .login-hero-component {
        /* margin-top: 20px; */
        margin-top: -100px;
    }
}

@media (max-width: 576px) {
    .main-container {
        flex-direction: column;
        align-items: center;
    }

    .sign-in {
        width: 90%;
        max-width: none;
    }

    .login-hero-component {
        width: 90%;
        max-width: none;
    }

    .login-hero-component {
        /* margin-top: 20px; */
    }
}