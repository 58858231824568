.email-thankyou-background {
    background: var(--rad, radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8F92DD 100%));
    width: 100%;
    /* height: 1024px; */
    /* height: 100vh; */
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;
    /* position: absolute; */
}

.email-hero-img {
    width: 40%;
    position: relative;
    margin-top: 450px;
    margin-left: 560px;
}

.email-thankyou-text {
    color: white;
    margin-top: 300px;
    font-size: 30px;
    text-align: center;
    margin-left: -200px;
    position: absolute;
    font-family: Poppins;
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0px;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.email-thankyou-main {
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins-Bold;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.email-main {
    margin: 0 auto;
}

.css-1t4vnk2-MuiDialogContent-root {
    padding: 0px!important;
}

@media (min-width: 576px ) and (max-width: 991px) {
    .email-thankyou-background {
        background: var(--rad, radial-gradient(868.33% 236.07% at 1.96% 0%, #373995 0%, #8F92DD 100%));
        width: 100%;
        height: 1024px;
        margin: 0 auto;
        /* position: absolute; */
    }

    .email-thankyou-main {
        font-size: 50px;
    }
    
    .email-thankyou-text {
        margin-top: 190px;
        margin-left: 24px;
        font-size: 24px;
        width: 62%;

    }

    .email-hero-img {
        width: 60%;
        position: relative;
        margin-top: 300px;
        margin-left: 222px;
    }
}

@media (max-width: 576px) {
    .email-thankyou-background {
        width: 100%;
        height: 1024px;
        margin: 0 auto;
    }

    .email-hero-img {
        width: 90%;
        margin-left: 20px;
    }

    .email-thankyou-main {
        font-size: 40px;
    }

    .email-thankyou-text {
        font-size: 16px;
        margin-top: 230px;
        margin-left: 20px;
        width: 90%;
        line-height: 24px;
        gap: 10px;
    }
}