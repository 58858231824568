.Footer-Container {
  background-color: #4e50a8;
  /* height: 232px; */
  /* max-width: 1440px !important; */
  margin: auto;
  /* margin-top: 20px; */
}
.Footer-Row1 {
  /* height: 152px; */
  margin: auto;
  /* border-bottom: 2px solid white; */
}
.Footer-Row2 {
  height: "80px";
}
.Footer-logo {
  display: flex;
  color: white;
  width: 143px;
  height: 58px;
  flex-direction: row;
  margin-left: 64px;
  flex-shrink: 0;
}
.Footer-copy-right {
  /* font-family: poppins; */
  /* margin-left: 64px; */
  /* font-size: 8px; */
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* margin-top: -8px !important; */
}
.Footer-Address {
  margin-bottom: 8px;
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.517px;
}
.Footer-Sub-Address {
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;

  /* B4 */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}
.Footer-links {
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  text-decoration-line: underline;
  font-family: Poppins;
  font-size: 9px;
}
.Footer-Contact-Head {
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;
  /* B2 */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.Footer-Contact-Body {
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;

  /* B4 */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}
/* .Footer-Social-Icon-Row {
  margin-left: 64px;
} */
.Footer-Social-Icon {
  height: 24px;
  width: 24px;
}
.Footer-Links-Left {
  margin-left: 64px;
  margin-top: 24px;
}
/* .Footer-Links-Right {
  margin-right: 64px;

  margin-top: 24px;
} */
.termsandconditions,
.privacypolicy {
  cursor: pointer;
}
@media (max-width: 576px) {
  .Footer-Container {
    background-color: #4e50a8;
    /* height: 289px; */
    width: 100%;
    /* margin-top: 40px; */
    margin: auto;
    padding-bottom: 16px;
  }
  .Footer-copy-right {
    margin-bottom: 8px;
    /* margin-left: 16px !important; */
    /* padding-left: 16px !important; */
    margin-top: 8px;
  }
  .Footer-Row1 {
    /* height: 241px; */
    margin: auto;
    /* border-bottom: 2px solid white; */
  }
  .Footer-Row2 {
    height: "45px";
  }
  .Footer-logo {
    width: 98px;
    height: 40px;
    margin: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 16px;
  }
  .Footer-Address {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.517px; /* 137.639% */
    margin-left: 16px;
  }
  .Footer-Sub-Address {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.011px; /* 137.639% */
    margin-left: 16px;
  }
  .Footer-Contact-Head {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.517px; /* 137.639% */
    margin-left: 16px;
  }
  .Footer-Contact-Body {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.011px; /* 137.639% */
    margin-left: 16px;
  }
  .Footer-Social-Icon-Row {
    margin-left: 16px;
  }
  .Footer-Social-Icon {
    width: 16px;
    height: 16px;
  }
  .Footer-links {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.011px; /* 137.639% */
    padding-left: 16px;
  }
  .Footer-Links-Left {
    margin-left: 16px;
  }
  .Footer-Links-Right {
    display: none;
  }
  .Footer-copy-right {
    margin-left: 16px;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .Footer-Container {
    /* margin-top: 40px; */
  }
  .Footer-logo {
    width: 98px;
    height: 40px;
  }
  .Footer-Address {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.517px; /* 137.639% */
  }
  .Footer-Sub-Address {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.011px; /* 137.639% */
  }
  .Footer-Contact-Head {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.517px; /* 137.639% */
  }
  .Footer-Contact-Body {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.011px; /* 137.639% */
    text-decoration-line: underline;
  }
  .Footer-Social-Icon-Row {
    /* margin-left: 16px; */
    gap: 15px !important;
  }
  .Footer-Social-Icon {
    width: 16px;
    height: 16px;
  }
  .Footer-links {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.011px; /* 137.639% */
    /* margin-top: 16px; */
    padding: 0px;
  }
  .Footer-Links-Left {
    margin-top: 16px;
  }
  /* .Footer-Links-Right {
    margin-right: 16px;
    margin-top: 16px;
  } */
}

@media (min-width: 993) {
  .Footer-Container {
    /* background-color: #4e50a8; */
    /* height: 232px; */
    max-width: 100% !important;
  }
  .Footer-Row1 {
    /* height: 152px; */
    margin: auto;
    /* border-bottom: 2px solid white; */
  }
  /* .Footer-Row2 {
    height: "80px";
  } */
  .Footer-logo {
    display: flex;
    color: white;
    width: 143px;
    height: 58px;
    flex-direction: row;
    margin-left: 64px;
    /* justify-content: center;   */
    flex-shrink: 0;
  }
  .Footer-Address {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.517px;
  }
  .Footer-Sub-Address {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;

    /* B4 */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
  }
  .Footer-links {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    text-decoration-line: underline;
  }
  .Footer-Contact-Head {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;

    /* B2 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  .Footer-Contact-Body {
    color: var(--white, #fff);
    font-feature-settings: "clig" off, "liga" off;

    /* B4 */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
  }
  /* .Footer-Social-Icon-Row {
    margin-left: 64px;
  } */
  .Footer-Social-Icon {
    height: 24px;
    width: 24px;
  }
  .Footer-Links-Left {
    margin-left: 64px;
    margin-top: 24px;
  }
  .Footer-Links-Right {
    margin-right: 64px;
    margin-top: 24px;
  }
}
