:target::before {
  content: "";
  display: block;
  height: 60px; /* Adjust this to match your header's height */
  margin: -60px 0 0; /* Adjust this to match your header's height */
}

.technologymain {
  background-color: #f0f2fd;
}
.technology-background {
  width: 100%;
  height: 850px;
  /* background-color: #f0f2fd; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  padding:60px 64px;
  max-width: 1440px;
  margin: 0 auto;
}

.technology-heading {
    color: #000;
    width: 500px;
    height: 56px;
    /* h2 */
    font-family: Poppins-medium;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 140% */
}

.technology-para {
  color: #000;

  /* p */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.technology-cards {
  display: flex;
  margin-inline-start: 0px;
  margin-inline-end: 20px;
  margin-top: 20px;
  padding: 0px 24px;
  align-items: center;
  flex-grow: 1;
  border-radius: 4px;
  background: #4e50a8 !important;
  height: 130px !important;
}

.card-Image {
    width: 96px;
    height: 96px;
    background: var(--white, #FFF);
    padding: 4px;
}

.card-row {
    display: flex;
    align-items: center;
    margin: 16px auto;    
}

.technology-cards-section {
  margin: 0 auto;
  max-width: 1440px;
}

/* .technology-cards {
    margin-top: 3px;
} */

.card-image-container {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.card-description {
    font-size: 1.3rem;
    margin-inline-end: 0px;
    line-height: 24px;
}




@media (min-width: 576px) and (max-width: 991px) {
  .card-description {
    font-size: 16px;
    line-height: 20px;
  }

    .card-Image {
        /* width: 48px;
        height: 48px;
        background: var(--white, #FFF);
        padding: 6px; */
        width: 50px;
        height: 50px;
        background: var(--white, #FFF);
        padding: 2px;
    }

  .technology-cards {
    height: 120px !important;
    padding: 16px 24px 16px 16px;
    margin-inline-start: 0px;
  }

    .card-row {
        padding: 0px;
        margin: 4px auto;
    }

  .technology-background {
    height: 100%;
  }

    .technology-group {
        width: 100%;
    }

    .card-7 {
        margin: -12px auto;
    }

    /*.card-3 {
        margin: 0px auto;
    } */
}

@media (max-width: 576px) {
  .technology-cards {
    height: 104px !important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .technology-heading {
    width: 300px;
  }

  .technology-background {
    padding: 24px 16px;
    height: 100%;
  }

  .card-description {
    font-size: 14px;
    line-height: 20px;
  }

    .card-Image {
        /* width: 36px;
        height: 36px;
        background: var(--white, #FFF);
        padding: 4px;
        margin: 12px auto; */
        width: 40px;
        height: 40px;
        background: var(--white, #FFF);
        padding: 2px;
        margin: 12px auto;
    }

    .card-row {
        margin: 16px auto;
    }

    .card-7 {
      margin: auto;
    }
}
