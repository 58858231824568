.dash-header {
    background: var(--white, #fff);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Added shadow */
    background-color: #ffffff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: transparent;
    position: static;
    /* max-width: 1440px; */
    margin: 0px;
    display: flex;
  }

  .dash-header-container {
    padding: 0px 64px !important;
    margin: 0px !important;
    max-width: 100% !important;
  }

  .dash-header {
    background: var(--white, #fff);
    box-shadow: 2px 0px 4px 0px rgba(255, 255, 255, 0.25);
    background-color: #ffffff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: transparent;
    position: static;
  }