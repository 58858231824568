/*Laptop*/
@media (min-width: 992px) {
  :target::before {
    content: "";
    display: block;
    height: 60px; /* Adjust this to match your header's height */
    margin: -60px 0 0; /* Adjust this to match your header's height */
  }
  .before-key {
    display: flex;
    justify-content: center;
    margin-left: 16px;
    margin-top: -10px;
    flex-grow: 0;
    max-width: 94%;
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 24px;
    margin: 0 auto;
  }

  .cards {
    width: 60%;
    max-width: 98%;
    justify-content: center;
    display: flex;
    flex-grow: 0;
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 24px;
    color: #4e50a8;
    margin-left: 16px;
    margin-top: 20px;
  }
  .card-text {
    color: #fdfdff;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .card-class {
    background-color: #4e50a8 !important;
    color: #fdfdff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    height: 344px !important;
  }

  .key-card-body {
    display: flex;
    flex-direction: column;
  }

  .card-icon {
    margin-top: 0%;
        padding-left: 105px;
        padding-top: 0px;
        width: 100%;
  }

  /* .card-icon { */
    /* padding-top: 85px; */
    /* padding-top: 10px;
    padding-left: 145px;
  } */

  .carousel-wrapper-II {
    max-width: 52%;
    /* overflow-x: hidden; */
  }

  .carousel-wrapper {
    max-width: 100%;
  }

  .keyfeaturesmain {
    background: #f0f2fd;
  }

  .key-background {
    /* background: #f0f2fd; */
    width: 100%;
    height: 500px;
    display: flex;
    max-width: 1440px;
    margin: auto;
    /* margin-top: 80px; */
  }

  .key-background > .key-para-group {
    font-family: Poppins-Medium;
    padding-top: 60px;
    padding-left: 64px;
    width: 100%;
    font-weight: 600;
  }

  .key-paragraph {
    font-family: Poppins;
    width: 100%;
    margin-top: 30px;
    font-weight: 500;
  }

  .emplify-title {
    font-family: Poppins-Medium;
  }

  .key-background > .key-para-group {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    transform: translateY(0);
  }

  .key-background > .key-para-group.hidden {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 2;
    left: 0;
    transform: translateY(-10px);
  }

  .carousel-control-next,
  .carousel-control-prev {
    bottom: 120px !important;
  }

  @keyframes fly-in {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0);
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 250px !important;
    width: 5%;
    height: 5%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-control-next.fly-in,
  .carousel-control-prev.fly-in {
    animation: fly-in 1s forwards;
  }

  .carousel-control-next {
    right: 0;
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none;
  }

  /* .carousel-control-prev img, .carousel-control-next img {
        width: 100%;
        height: auto;
      } */
}

/* @media (min-width: 576px) and (max-width: 991px) {
  .before-key {
    width: 60%;
    display: flex;
    justify-content: center;
    margin-left: 16px;
    margin-top: -70px;
    flex-grow: 0;
    max-width: 95%;
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 24px;
  }
  .key-background {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f0f2fd;
    padding: 16px 18px;
  }

  .key-para-group {
    font-family: Poppins-Medium;
    padding-top: 20px;
    padding-left: 25px;
    width: 100%;
    font-weight: 600;
  }

  .key-paragraph {
    font-family: Poppins;
    width: 100%;
    margin-top: 30px;
    font-weight: 500;
  }

  .emplify-title {
    font-family: Poppins-Medium;
  }

  .carousel-wrapper-II {
    max-width: 100%;
  }
  .carousel-wrapper {
    max-width: 98%;
  }

  .card-class {
    background-color: #4e50a8 !important;
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .card-text {
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .card-icon {
    width: 100%;
  }

  .card-icon {
    padding-top: 85px;
    padding-left: 180px;
  }
} */

/* @media (max-width: 576px) {
  .before-key {
    width: 60%;
    display: flex;
    justify-content: center;
    margin-left: 16px;
    margin-top: -70px;
    flex-grow: 0;
    max-width: 95%;
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 24px;
  }
  .key-background {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f0f2fd;
  }

  .key-paragraph {
    font-family: Poppins;
    font-size: 12px;
  }

  .emplify-title {
    font-family: Poppins-Medium;
  }

  .key-heading {
    font-size: 16px;
  }

  .key-para-group {
    font-family: Poppins-Medium;
    padding-top: 20px;
    padding-left: 25px;
    width: 94%;
  }
  .carousel-wrapper-II {
    max-width: 100%;
  }

  .card-class {
    width: 300px;
    height: 400px;
    background-color: #4e50a8 !important;
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .card-text {
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .card-icon {
    width: 100%;
  }

  .card-icon {
    padding-top: 85px;
    padding-left: 180px;
  }
} */


@media (min-width: 576px) and (max-width: 991px) {
  .before-key {
    width: 60%;
    display: flex;
    justify-content: center;
    margin-left: 16px;
    /* margin-top: -70px;  */
    flex-grow: 0;
    max-width: 95%;
    /* padding-top: 80px; */
    /* padding-bottom: 80px; */
    gap: 24px;
  }
  .key-background {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f0f2fd;
    /* margin-top: 60px; */
    padding: 16px 18px;
  }
 
  .key-para-group {
    font-family: Poppins-Medium;
    padding-top: 20px;
    padding-left: 25px;
    width: 100%;
    font-weight: 600;
  }
 
  .key-paragraph {
    font-family: Poppins;
    width: 100%;
    margin-top: 30px;
    font-weight: 500;
  }
 
  .emplify-title {
    font-family: Poppins-Medium;
  }
 
  .carousel-wrapper-II {
    max-width: 100%;
    /* overflow-x: hidden; */
  }
  .carousel-wrapper {
    margin: auto;
    max-width: 98%;
  }
 
  .card-class {
    background-color: #4e50a8 !important;
    display: flex;
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
 
  .card-text {
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: auto;
  }

  .key-card-body {
    display: flex;
    flex-direction: row;
  }

  .card-icon {
    width: 60%;
    height: 70%;
    margin: auto;
  }
 
  .card-icon {
    /* padding-top: 85px; */
    /* padding-left: 180px; */
  }
}
 
@media (max-width: 576px) {
  .before-key {
    width: 60%;
    display: flex;
    justify-content: center;
    margin-left: 16px;
    /* margin-top: -70px; */
    flex-grow: 0;
    max-width: 95%;
    /* padding-top: 80px; */
    /* padding-bottom: 80px; */
    gap: 24px;
  }
  .key-background {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f0f2fd;
    /* margin-top: 40px; */
    padding-bottom: 20px;
  }
 
  .key-paragraph {
    font-family: Poppins;
    font-size: 12px;
  }
 
  .emplify-title {
    font-family: Poppins-Medium;
  }
 
  .key-heading {
    font-size: 16px;
  }
 
  .key-para-group {
    font-family: Poppins-Medium;
    padding-top: 20px;
    padding-left: 25px;
    width: 94%;
  }
  .carousel-wrapper-II {
    max-width: 100%;
    /* overflow-x: hidden; */
  }
 
  .card-class {
    width: 300px;
    height: 190px;
    background-color: #4e50a8 !important;
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .card-img, .card-img-bottom, .card-img-top {
    width: 75%;
}
 
  .card-text {
    color: #fdfdff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: auto;
  }
  .card-icon {
    width: 60%;
    height: 70%;
    margin: auto;
  }

  .key-card-body {
    display: flex;
    flex-direction: row;
  }
 
  /* .card-icon {
    padding-top: 85px;
    padding-left: 180px;
  } */
}
 