.header {
  /* max-width: 1440px; */
  margin: auto;
  /* margin-bottom: 2rem; */
}
.header-container {
  /* padding: 0px 64px 0px auto !important; */
  padding-right: 0px !important;
  margin: 0px !important;
  max-width: 100% !important;
}
/* 
.navigation-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 24px;
  position: relative;
  background-color: #4e50a8;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 24px;
}

.navigation-button .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Poppins";
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  height: 40px !important;
} */

.header {
  background: var(--white, #fff);
  box-shadow: 0px 0px 4px 0px rgb(86 82 82 / 25%);
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: transparent;
  /* position: static; */
}

.nav-link {
  padding: 8px 16px !important;
  color: #212121 !important;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px;
}
#request-demo-button {
  /* width: 181px !important; */
  width: 156px !important;
  padding: 8px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 8px !important;
  background-color: #4e50a8 !important;
  color: white !important;
  /* color: #4e50a8 !important; */
  border: 1px solid #4e50a8 !important;
  margin-right: -50px;
}
#request-demo-button:hover {
  background-color: #3a3b7e !important;
  color: white !important;
}

.nav-link:hover {
  color: #4e50a8 !important;
}

.navbar-toggler {
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  padding: 0px !important;
}
.toggler-image {
  height: "48px";
  width: "48px";
  margin-top: "0px";
  padding: "0px";
}

.modal-90w .modal-dialog {
  margin-top: 19px;
  margin-right: 54px !important;
  max-width: 181px !important;
  border-radius: 0px !important;
}
.popup-dropdown-menu {
  text-align: center !important;
  width: 181px !important;
  border-radius: 8px !important;
  margin-right: 3rem !important;
}

.modal-button {
  max-width: 181px !important;
  /* border-top: 0.5px solid #4e50a8 !important;
  border-right: 0.5px solid #4e50a8 !important;
  border-left: 0.5px solid #4e50a8 !important; */
  border-radius: 0px !important;
  padding: 8px 0px !important;
  margin: auto !important;
}
.modal-button-home {
  max-width: 181px !important;
  border-right: 0.5px solid #4e50a8 !important;
  border-left: 0.5px solid #4e50a8 !important;
  border-radius: 0px !important;
  padding: 8px 0px !important;
  margin: auto !important;
}
/* .ModalRequestbutton {
  color: var(--Primary-Color, #4e50a8) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
} */

.ModalRequestbutton {
  border-top: 0.5px solid white !important;
  border-right: 0.5px solid white !important;
  border-left: 0.5px solid white !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  padding: 3% 0px !important;
  margin: auto !important;
  margin-bottom: 2px !important;
  color: white !important;
  /* color: #4e50a8 !important; */
  border-radius: 8px !important;
  width: 150px !important;
  /* height: 35px !important; */
  background-color: #4e50a8 !important;
}

a.modal-button-1.dropdown-item:hover {
  background-color: #4e50a8 !important;
  color: white;
}

@media (min-width: 976px) {
  .header-container {
    padding: 0px 64px !important;
    margin: 0px !important;
    max-width: 100% !important;
  }
  .dropdown-menu {
    /* width: 200px !important; */
    /* min-width: 112px !important; */
    /* max-width: 280px !important; */
    padding: 0px 0px !important;
    border-radius: 0px !important;
    border-right: 1px solid var(--white, #fff) !important;
    border-bottom: 1px solid var(--white, #fff) !important;
    border-left: 1px solid var(--white, #fff) !important;
    /* background: #7b7ecd !important; */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  }
  .dropdown-item {
    margin-top: 8px !important;
    padding: 16px 12px !important;
    margin: auto !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    /* color: var(--white, #fff) !important; */
    font-family: Poppins-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
  .dropdown-item:hover {
    /* background-color: #7376c0 !important; */
    background-color: #4e50a8 !important;
    color: var(--white, #fff) !important;
  }
  .navbar-toggler {
    display: none;
  }
}

@media (min-width: 577px) and (max-width: 976) {
  .header-container {
    height: 480px !important;
  }
  .brand-image {
    height: 40px !important;
    width: 94px !important;
    padding: 12px 0px !important;
  }
  .ModalRequestbutton {
    width: 130px !important;
    border-top: 0.5px solid white !important;
    border-right: 0.5px solid white !important;
    border-left: 0.5px solid white !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    padding: 3% 0px !important;
    margin: auto !important;
    margin-bottom: 2px !important;
    color: white !important;
    /* color: #4e50a8 !important; */
    border-radius: 8px !important;
    width: 120px !important;
    /* height: 35px !important; */
    background-color: #4e50a8 !important;
  }
  .modal-button-1 {
    width: 158px !important;
    /* border-top: 0.5px solid white !important;
    border-right: 0.5px solid white !important;
    border-left: 0.5px solid white !important;
    border-radius: 0px !important; */
    font-size: 14px !important;
    padding: 8px 0px !important;
    margin: 0 !important;
  }

  .modal-button-1:hover {
    background-color: #4e50a8 !important;
    color: white;
  }

 
}

@media (max-width: 576px) {
  .header {
    height: 48px !important;
    margin-top: 0px;
    padding: 0px !important;
  }
  .nav-brand {
    height: 48px !important;
  }
  .brand-image {
    height: 40px !important;
    width: 94px;
  }
  .header-container {
    padding: 0px 16px !important;
    margin: 0px !important;
    max-width: 100% !important;
  }
  .navbar-toggler {
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    padding: 0px !important;
  }
  .modal-90w .modal-dialog {
    margin-top: 10px;
    margin-right: 21px !important;
    margin-left: auto;
    width: 130px !important;
    border-radius: 0px !important;
  }

  .modal-button-home {
    margin-top: -10px !important;
    padding-top: 0px !important;
    width: 130px !important;
    border-right: 0.5px solid #4e50a8 !important;
    border-left: 0.5px solid #4e50a8 !important;
    /* border-radius: 0px !important; */
    font-size: 14px !important;
    /* padding: 8px 0px !important; */
    margin: auto !important;
  }
  .modal-button {
    width: 128px !important;
    border-top: 0.5px solid white !important;
    border-right: 0.5px solid white !important;
    border-left: 0.5px solid white !important;
    border-radius: 0px !important;
    font-size: 14px !important;
    padding: 8px 0px !important;
    margin: auto !important;
  }

  /* .modal-button:hover {
    background-color: #4e50a8 !important;
    color: white;
  } */

  .modal-button-1 {
    width: 158px !important;
    /* border-top: 0.5px solid white !important;
    border-right: 0.5px solid white !important;
    border-left: 0.5px solid white !important;
    border-radius: 0px !important; */
    font-size: 14px !important;
    padding: 8px 0px !important;
    margin: 0 !important;
  }

  .modal-button-1:hover {
    background-color: #4e50a8 !important;
    color: white;
  }
  .ModalRequestbutton {
    width: 130px !important;
    border-top: 0.5px solid white !important;
    border-right: 0.5px solid white !important;
    border-left: 0.5px solid white !important;
    border-bottom: 0.5px solid white !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    padding: 3% 0px !important;
    margin: auto !important;
    margin-bottom: 7px !important;
    color: white !important;
    /* color: #4e50a8 !important; */
    border-radius: 8px !important;
    width: 120px !important;
    /* height: 35px !important; */
    background-color: #4e50a8 !important;
  }
  .ModalRequestbutton:hover {
    background-color: white !important;
    color: #4e50a8 !important;
  }
  .popup-dropdown-menu {
    margin-right: 0px !important;
    text-align: center !important;
    width: 100px !important;
    border-radius: 8px !important;
    margin-right: 1.5rem !important;
  }
  .dropdown-item {
    /* width: 130px !important; */

    font-family: Poppins;
    font-size: 11px !important;
    font-style: normal;

    letter-spacing: 0.5px;
  }
}
