:target::before {
  content: "";
  display: block;
  height: 60px; /* Adjust this to match your header's height */
  margin: -60px 0 0; /* Adjust this to match your header's height */
}

.platformContainerMain {
  margin: auto;
  /* padding-top: 60px; */
  margin-bottom: 20px;
  max-width: 1440px;
}
.platformContainer {
  /* margin-right: 64px;
  margin-left: 64px; */
  margin-right: 42px;
  margin-left: 42px;
  padding: 0;
  /* height: 416px; */
  /* max-width: 1440px; */
}
#platformCard {
  padding: 0;
  border: none !important;
}
#platformCard .platformTitle {
  /* font-weight: 500; */
  font-size: 30px;
  /* font-weight: bold; */
  text-align: start;
  margin-bottom: 1rem;
  font-family: 'Poppins-Medium';
}
#platformCard .platformBody {
  padding: 0;
  padding-top: 1.5rem;
}

.platformText {
  text-align: left;
  font-size: 16px !important;
  word-spacing: 1px;
  line-height: 1.5rem;
  color: black;
}
.platformImage {
  width: 90%;
  padding: auto;
  height: 20rem;
  display: none;
}
/* .platformContainer .ImageCol {
  margin: auto;
} */

#platformCard .platformImage1 {
  width: 100%;
  display: none;
}

.play-text {
  font-family: Poppins-Medium;
}

@media (max-width: 576px) {
  .platformContainer {
    margin-right: 16px;
    margin-left: 16px;
    padding: 0;
    max-width: 100%;
  }
  #platformCard .platformImage1 {
    display: block;
  }
  #platformCard .platformTitle {
    font-size: 1rem;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
    font-family: 'Poppins-Medium';
  }
}
@media (min-width: 577px) and (max-width: 992px) {
  .platformImage {
    width: 100%;
    display: block;
  }
  #platformCard .platformTitle {
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
    font-family: 'Poppins-Medium';
  }
  .platformContainer {
    height: 100%;
  }
}

@media (min-width: 993px) {
  .platformImage {
    width: 100%;
    display: block;
  }
  #platformCard .platformText {
    text-align: justify;
    font-size: 1.3rem;
    word-spacing: 1px;
    line-height: 1.5rem;
  }
  .platformContainer .platformImage {
    width: 100%;
    height: 18rem;
    padding: auto;
  }
  .platformContainer .ImageCol {
    margin: auto;
  }
}
